import { faFacebook, faInstagram, faXTwitter, faYoutube } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <footer className="footer-section">
      <div className="mycontainer">
        <div className="main">
          <p className="has-text-centered mb-5">
            Copyright {currentYear}. Design and development by: <Link to="//www.buymeacoffee.com/detachment/" target="_blank"><span>Buddhism Nature</span></Link>
          </p>
            <ul>
              <li>
                <Link to="//www.facebook.com/groups/vmhsuanhua/" target="_blank">
                  <FontAwesomeIcon icon={faFacebook} className="social-icon" />
                </Link>
              </li>
              <li>
                <Link to="//www.youtube.com/channel/UCkS6AkfuTUCh8ssTqSggvQQ/playlists" target="_blank">
                  <FontAwesomeIcon icon={faYoutube} className="social-icon" />
                </Link>
              </li>              
            </ul>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
