import { useTranslation } from "react-i18next";
import experiences from "../data/Experiences";

const Experience = () => {
  const {t, i18n} = useTranslation();

  
  return (
    <section className="experience section" id="experience" data-aos="fade-up">
      <div className="mycontainer">
        <div className="infos mb-6">
          <h2 className="section-title has-text-centered">
            {t(`experience.name_section`)}
          </h2>
          <p className="has-text-centered m-auto">
          {t(`experience.subtitle`)}
          </p>
        </div>
        <div className="columns is-vcentered">
          <div className="column">
            <div id="education">
              <div className="fullWidth eight columns">
                <ul className="cbp_tmtimeline">
                
                  {experiences.map((experience, index) => (
                    <li className="bar" key={index}>
                      <div className="cbp_tmlabel">
                        <h3>{ i18n.language === "en" ? experience.titleEn : i18n.language === "zh" ? experience.titleZh : experience.titleEn }</h3>
                        <h4>{ i18n.language === "en" ? experience.companyEn : i18n.language === "zh" ? experience.companyZh : experience.companyEn }</h4>
                        <div className="date">
                          <i className="fa fa-calendar"></i> { i18n.language === "en" ? experience.dateEn : i18n.language === "zh" ? experience.dateZh : experience.dateEn }
                        </div>
                        <p className="projectParagraph">
                          { i18n.language === "en" ? experience.descriptionEn : i18n.language === "zh" ?  experience.descriptionZh : experience.descriptionEn }
                        </p>
                      </div>
                    </li>
                  ))}


                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Experience;
