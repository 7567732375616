import React, { useEffect, useState } from "react";
import blogsData from "../data/News";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom/dist";

const News = ({ data }) => {
  const { i18n, t } = useTranslation();
  const articlesPerPage = 6; // Number of articles to display per page

  const [currentPage, setCurrentPage] = useState(1);
  const [blog, setBlogs] = useState([]);
  const totalArticles = blogsData.length;
  const totalPages = Math.ceil(totalArticles / articlesPerPage);

  useEffect(() => {
    if (data === "*") {
      setBlogs(
        blogsData.slice(
          (currentPage - 1) * articlesPerPage,
          currentPage * articlesPerPage
        )
      );
    } else {
      setBlogs(blogsData.slice(0, articlesPerPage));
    }
  }, [data, currentPage]);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  return (
    <>
      <section id="news" className="blog section" data-aos="fade-up">
        <div className="mycontainer">
          {/* INFO SECTION START */}
          <div className="level">
            <div className="level-left">
              <div className="section-title-container">
                <h2 className="section-title">{t(`articles.name_section`)}</h2>
                <p>{t(`articles.subtitle`)}</p>
              </div>
            </div>

            {data !== "*" && (
              <div className="level-right">
                <Link
                  to={`/news`}
                  className="button outlined-button is-rounded articles-button has-text-white"
                >
                  {t(`articles.all_articles`)}
                </Link>
              </div>
            )}
          </div>
          {/* INFO SECTION END */}

          <div className="columns is-multiline">
            {blog?.map((item, index) => (
              <div className="column is-4" key={index}>
                <div className="card is-shadowless">
                  <div className="card-image">
                    <Link to={`/news/blog/${item.id}`}>
                      <figure className="image is-4by3">
                        <img
                          src={item.imageUrl}
                          alt="Parkinson's Awareness: What You Need to Know"
                        />
                      </figure>
                    </Link>
                  </div>
                  <div className="card-content">
                    <div className="blog-header">
                      <div className="post-metatag">
                        <span className="icon">
                          <i className="fa fa-calendar" aria-hidden="true"></i>
                        </span>
                        <h4 className="post-date">
                          {i18n.language === "en"
                            ? item.dateEn
                            : i18n.language === "zh"
                              ? item.dateZh
                              : item.dateEn}
                        </h4>
                      </div>
                      <h2>
                        <Link to={`/news/blog/${item.id}`}>
                          {i18n.language === "en"
                            ? item.titleEn
                            : i18n.language === "zh"
                              ? item.titleZh
                              : item.titleEn}
                        </Link>
                      </h2>
                      <Link
                        to={`/news/blog/${item.id}`}
                        className="is-fullwidth primary-button button  is-rounded has-text-white"
                      >
                        {i18n.language === "en"
                          ? item.buttonEn
                          : i18n.language === "zh"
                            ? item.buttonZh
                            : item.buttonEn}
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>

          {/* Pagination */}
          {data === "*" && (
            <nav className="pagination" aria-label="pagination">
              <a
                className={`pagination-previous ${currentPage === 1 ? "is-hidden" : ""
                  }`}
                title="Previous Page"
                onClick={() => handlePageChange(currentPage - 1)}
              >
                {t(`articles.previous`)}
              </a>
              <a
                className={`pagination-next ${currentPage === totalPages ? "is-hidden" : ""
                  }`}
                onClick={() => handlePageChange(currentPage + 1)}
              >
                {t(`articles.next`)}
              </a>
              <ul className="pagination-list">
                {Array.from({ length: totalPages }, (_, i) => (
                  <li key={i}>
                    <a
                      className={`pagination-link ${currentPage === i + 1 ? "is-current" : ""
                        }`}
                      aria-label={`Page ${i + 1}`}
                      onClick={() => handlePageChange(i + 1)}
                    >
                      {i + 1}
                    </a>
                  </li>
                ))}
              </ul>
            </nav>
          )}
        </div>
      </section>
    </>
  );
};

export default News;
