import workOne from "../assets/images/portfolio/img-1.jpg";
import workTwo from "../assets/images/portfolio/img-2.jpg";
import workThree from "../assets/images/portfolio/img-3.jpg";
import workFour from "../assets/images/portfolio/img-4.jpg";
import workFive from "../assets/images/portfolio/img-5.jpg";
import workSix from "../assets/images/portfolio/img-6.jpg";
import workSeven from "../assets/images/portfolio/img-7.jpg";
import workEight from "../assets/images/portfolio/img-8.jpg";
import workNine from "../assets/images/portfolio/img-9.jpg";
import workTen from "../assets/images/portfolio/img-10.jpg";
import workEleven from "../assets/images/portfolio/img-11.jpg";
import workTwelve from "../assets/images/portfolio/img-12.jpg";
import workThirdteen from "../assets/images/portfolio/img-13.jpg";
import workFourthteen from "../assets/images/portfolio/img-14.jpg";
import workFifthteen from "../assets/images/portfolio/img-15.jpg";

const worksData = [
  {
    category: "monk",
    imageUrl: workOne,
  },
  {
    category: "temple",
    imageUrl: workThirdteen,
  },
  {
    category: "monk",
    imageUrl: workTwo,
  },
  {
    category: "temple",
    imageUrl: workFourthteen,
  },
  {
    category: "monk",
    imageUrl: workThree,
  },
  {
    category: "temple",
    imageUrl: workFifthteen,
  },
  {
    category: "monk",
    imageUrl: workFour,
  },
  {
    category: "temple",
    imageUrl: workEleven,
  },
  {
    category: "monk",
    imageUrl: workFive,
  },
  {
    category: "temple",
    imageUrl: workEight,
  },
  {
    category: "monk",
    imageUrl: workSix,
  },
  {
    category: "temple",
    imageUrl: workSeven,
  },
  {
    category: "temple",
    imageUrl: workNine,
  },
  {
    category: "temple",
    imageUrl: workTen,
  },
  {
    category: "temple",
    imageUrl: workTwelve,
  },
];

export default worksData;
