import serviceOne from "../assets/images/services/icon-1.png";
import serviceTwo from "../assets/images/services/icon-2.png";
import serviceThree from "../assets/images/services/icon-3.png";

import FirstImage from "../assets/images/services/img-1.jpg";
import SecondImage from "../assets/images/services/img-2.jpg";
import ThirdImage from "../assets/images/services/img-3.jpg";

const serviceData = [
  
  {
    id: 3,
    srcImage: ThirdImage,
    iconSrc: serviceThree,
    titleEn: "Hundred Parables Sutra",
    titleZh: "百喻經",
    dateEn: "Sutra",
    dateZh: "咒經",
    descriptionEn:
      "100 stories are used by the Buddha to demonstrate the principles of dharma to laymen and people unfamiliar with his ideas.",
    descriptionZh:
      "這些小故事風格接近寓言故事，通俗易懂，用來比喻佛教的基本思想。每篇經文都由「喻」和「法」兩部分組成。「喻」是一個簡短的寓言，「法」是寓言所蘊含的教誡。",
    contentEn: `
      <h2>Parable 1. The Stupid Man and The Salt</h2>
      <p>
      Once there was a stupid man who paid a visit to a friend. When the host gave him something to eat, he complained that the food was tasteless, so the host added a bit of salt. The stupid man tried the food again and found it much improved. He thought, “If a little salt improves it this much, then more salt would make it even better.”

      Then he ate only salt and his mouth became raw and he became sick.
      
      ​This is like those non-buddhists who, upon hearing that fasting can enable one  to attain the Way, fast for seven or fifteen days; they end up hungry and distressed while attaining nothing beneficial to the Way. They are just like the stupid person, who ate the plain salt, thinking that if salt enhanced foods’ flavors so much, eating plain salt would be even more delicious. His mouth ended up raw. This is just like that.   
      </p>

      <h2>Parable 2. The Stupid Man Tries To Store Up Milk </h2>
      <p>
      Once there was a stupid man who was going to have some guests and decided to store up some milk for them. He thought, “If I milk the cow every day beforehand, there will be too much milk and not enough space to store it. It will probably spoil. It would be better to leave it in the cow. I’ll milk the cow right before the guests arrive.”

      Then he separated the cow from its calf and tied them up. A month later, when he actually had the gathering, he tried to milk the cow, but found that the milk had run dry. Some guests scoffed at him and others laughed.

      This is like stupid people who want to give alms all at once and say that they will wait until they possess great wealth. Usually, before they can accumulate enough money, it is seized by the government, lost in a fire or flood, or taken by robbers. Or else they may even die before they get around to giving. They are just like that stupid man who tried to store up milk.  
      </p>
      
    `,
    contentZh: `
      <h2>(一)愚人食鹽喻</h2>
      <p>
      昔有愚人至於他家，主人與食，嫌淡無味。主人聞已更為益鹽。既得鹽美，便自念言：「所以美者，緣有鹽故，少有尚爾，況復多也？」

      愚人無智，便空食鹽，食已口爽，返為其患。譬彼外道聞節飲食可以得道，即便斷食，或經七日，或十五日，徒自困餓，無益於道，如彼愚人，以鹽美故而空食之，致令口爽，此亦復爾。 
      </p>

      <h2>(二)愚人集牛乳喻</h2>
      <p>
       昔有愚人，將會賓客，欲集牛乳，以擬供設，而作是念：
       
       我今若豫於日日中 取牛乳，牛乳漸多，卒無安處；或復酢敗，不如即就牛腹盛之，待臨會時當頓取。作是念已，便捉牸牛母子，各繫異處。却後一月，爾乃設會，迎置賓客；方牽牛來欲取乳，而此牛乳即乾無有。時為眾賓，或瞋或笑。
       
       愚人亦爾，欲修布施，方言：
       「待我大有之時，然後頓施。」未及聚頃，或為縣官、水、火、盜賊之所侵奪，或卒命終，不及時施。彼亦如是。  
      </p>
         
    `,
  },
  {
    id: 1,
    srcImage: FirstImage,
    iconSrc: serviceOne,
    titleEn: "Shurangama Mantra",
    titleZh: "楞嚴咒",
    dateEn: "Mantra",
    dateZh: "咒",
    descriptionEn:
      "The King of all Mantra",
    descriptionZh:
      "究竟堅固定中王",
    contentEn: `
      <h2>The Efficacious Language of Heaven and Earth</h2>
      <p>
      Now I am explaining the Shurangama Mantra for you and it is extremely difficult to encounter such a rare Dharma assembly as this. Billions of eons pass and no one explains the Shurangama Mantra even once. Nor is it easy to explain even once. When I am explaining, I know full well that no one understands what's being said. Even if there are those who think they do, they don't really understand. Some think they already understand and so they don't pay attention, but that's also failing to understand.
      </p>
      <p>
      Among the Buddha's teachings, the Shurangama Mantra is considered to be the king of mantras because it is the longest and most important. The flourish or demise of Buddhism rests entirely with the Shurangama Mantra. It is the efficacious phrases of the Shurangama Mantra that keep heaven and earth from being destroyed. It is the efficacious phrases of the Shurangama Mantra that keep the world from coming to an end. That is why I often tell you that as long as a single person can recite the Shurangama Mantra, the world cannot be destroyed, nor can Buddhism. But when there is no longer anyone who can recite the Shurangama Mantra, then very quickly the world will be destroyed, because the Proper Dharma no longer abides.
      </p>

      <p>
      Now there are even heavenly demons and externalists who claim that the Shurangama Sutra and the Shurangama Mantra are false. These heaven demons and externalists send their demon sons and grandsons to stir up rumors that cause people to not believe in the Shurangama Sutra and the Shurangama Mantra. This sutra and mantra are critically important to the preservation of the Proper Dharma.
      </p>

      <p>
      The Shurangama Sutra was spoken for the sake of the Shurangama Mantra. There's no way to ever finish expressing the importance of the Shurangama Sutra and the Shurangama Mantra; to the ends of all time their merits, virtues, and wonderful functions could never be told--so absolutely inconceivable and ineffable are they! When all is said and done, the Shurangama Sutra is an ode to the Shurangama Mantra. As long as there is even one person who can recite the Shurangama Mantra, the demons, ghosts, and strange entities don't dare show themselves in this world. They fear the mantra.
      </p>

      <p>
      But when not even one person can recite the Shurangama Mantra by heart, then those weird entities, those demons and ghosts will come out of hiding. Depraved and up to no good, they will not be recognized by most people. At this point in time, since there are still those who can recite the mantra from memory, those malevolent beings haven't made their appearance yet. And so, if you want to keep the world from being destroyed, quickly learn the Shurangama Mantra and read the Shurangama Sutra to keep the Proper Dharma in the world.
      </p>       
    `,
    contentZh: `
      <h2>支援世界不到末日的靈文</h2>
      <p>
      我現在給你們講楞嚴咒，這楞嚴咒百千萬劫也沒有人講一次，也不容易講一次。在我給各位講時，我知道沒有人聽得懂。就是有人自以爲懂得，也不是真懂。有人自以爲懂了，所以不注意，那也等於不懂。楞嚴咒是支援天地沒有毀滅的靈文，楞嚴咒是支援世界不到末日的靈文。所以我常說，世上若有一人會念楞嚴咒，這世界就不會毀滅，法也不會滅的。等到世上沒有人會念楞嚴咒時，那時佛法就該滅了。
      </p>
      <p>
      現在便有一些天魔外道，造出謠言來說，楞嚴經和楞嚴咒都是假的。這是天魔外道派出的魔子魔孫來造出這些謠言，令人不相信楞嚴經和楞嚴咒。到人人都不相信楞嚴咒，就沒人會念，這世界就快毀滅了。若想世界不滅，就快念楞嚴咒、讀楞嚴經。若你能天天念楞嚴咒，就是危險的原子彈、氫氣彈也不會到你身邊。所以你應該一心念楞嚴咒。
      </p>
      <p>
      我現在給你們講楞嚴咒，當時可能沒有人明白，可是十年、百年、千年後，有人見到這樣淺的批註，那時對楞嚴咒就會深深地明瞭了。你們聽了楞嚴咒，不要以爲這是很容易的。這四句偈頌看起來很簡單，但它是從我心性裏流露出來的，並不是尋章摘句，在其他的書本上抄來的。你們跟我學，不管偈頌好壞，這是按我的看法，對楞嚴咒的瞭解而寫出的。你們若想真明白楞嚴咒，應該注意地學習，不要把時間空過了。
      </p>     
    `,
  },
  {
    id: 2,
    srcImage: SecondImage,
    iconSrc: serviceTwo,
    titleEn: "Great Compassion Mantra",
    titleZh: "大悲咒",
    dateEn: "Mantra",
    dateZh: "咒",
    descriptionEn:
      "The Great Compassion Mantra Can Dispel Calamities",
    descriptionZh:
      "大悲咒能消災難",
    contentEn: `
      <h2>Great Compassion Mantra</h2>
      <p>
      Despite the hot weather, people have not been put off and have rushed here to attend the session, undaunted by the long journey and arduous climb. Ultimately, what advantages does this have? One cannot speak of true advantages. Just as someone will know the warmth or coolness of a glass of water only after drinking from it, true-hearted people will naturally understand the wonder in this.
      </p>
      <p> 
      How can one obtain the advantages? There's no way aside from what I just said─you must recite the Bodhisattva's name with a true heart. A true heart is just a concentrated mind. It is said, "When one is concentrated, it is efficacious. When one is scattered, there is nothing." If you can be single-minded, you will obtain a spontaneous response in the Way. The power of the response in the Way is inconceivable. However, you must apply the effort yourself; no one can do it for you, and even less can you attain it by chance.
      </p>
      <p> 
      Consider the example of “talking about food and counting others' wealth.” If someone talks about how nutritious the food is, but doesn't eat it, then even if it really is nutritious, how can the person get the nutrition? It is said:
      </p>
      <p>
      All day long you count the money of others,
      But you don't own half a cent yourself.
      If you don't cultivate the Dharma,
      You make the same mistake.
      </p>
    　<p>
      It is also the same when we recite the Bodhisattva's name. If we just know and talk about the merit and virtue of reciting the Bodhisattva's name, it doesn't count. We have to truly recite until we are single-minded and unconfused, and even further, until the sounds of the water and wind in our ears are just the recitation of the Bodhisattva's vast name. It is said,
      </p>
      <p>
      Sentient creatures and insentient things,
      All proclaim the wonderful Dharma of the Mahayana.
      </p>
      <p>
      If you have not reached that level of being single-minded and unconfused, then everything will be jumbled together. When the wind blows, you just hear a whooshing sound; when the water flows, you just hear the roaring sound. You cannot perceive the wonder in it. So we should honestly recite and not let any idle thoughts mingle in our recitation. Only then can we derive the benefits of the Dharma.
      </p>
      <p>
      This time when we hold the session, we should pay attention to the rules for the session. As it is said, without a compass and a ruler, you can't draw circles and squares. The traditional rule of this temple is to avoid causing a disturbance, so that you won't hinder others from cultivating.
      </p>
　    <p>
      During these seven days, we will also recite the Great Compassion Mantra. The merit and virtue of the Great Compassion Mantra is inconceivable. Without considerable good roots, it's not easy to even hear the three words “Great Compassion Mantra.” Now everyone can not only hear the name, but also uphold and recite it. That proves that you all have tremendous good roots, which you planted when there were Buddhas in the world. Since you have such great good roots, you should not casually let this life go to waste.
      </p>
      <p>  
      I remember that when the temple held the first sessions, eight or nine out of ten laypeople didn't know how to recite the Great Compassion Mantra. And now, eight or more out of ten laypeople can recite it. That shows the progress made by the laity. Now I'll tell a story which proves the merit and virtue of the Great Compassion Mantra.
      </p>
      <p>
      In Manchuria, there was once a wealthy man who owned a great deal of land. One autumn, he accompanied four or five large cargo trucks carrying full loads of sorghum to be sold in the city. Since the city was over a hundred and fifty li from the village, he started out at a little past one o'clock in the morning. Unfortunately, he ran into some bandits on the road. Seeing them up ahead, the rich man started reciting the Great Compassion Mantra. Strangely enough, the gang of bandits were just like blind men and didn't notice his trucks at all. Thus, they passed safely through the difficulty. That's one of the efficacious responses of the Great Compassion Mantra that I have personally heard about.
      </p>
      <p>
      In the Great Compassion Mind Dharani Sutra, it says, “Those who recite the Great Compassion Mantra can dispel all disasters. They will not be burned by fire or drowned by water.” So I urge the laypeople who can already recite it to do so at least three times a day. Those who cannot recite it should learn quickly. The merit and virtue of the Great Compassion Mantra can not only make thieves and robbers go away, it can also dispel the myriad illnesses, and resolve all demonic troubles. So we should sincerely recite it.
      </p>
      <p>
      On this starting day of the session, there is a very optimistic spirit in the Dharma Assembly, and everyone is taking it very seriously. I hope everyone will make a determined effort, and strive to be ever more vigorous.
      </p>
     `,
    contentZh: `
      <h2>大悲咒</h2>
      <p>
      在天氣炎熱中，大家能不畏熱，也不怕山高路遠地趕來參加打七，究竟有什麼好處？真正的好處，是說不出來的，如人飲水，冷暖自知，唯有真心的人，才能自然而然地領會到其中的妙處。
      </p>
      <p>
      怎麼樣才能獲得好處呢？沒有其他，正如方才所說，只要真心地念菩薩的名號。真心，換句話來說就是專心；所謂「專一則靈，分歧則弊」，能夠專一，就自然能夠感應道交，這種感應道交的力量，是不可思議的，可是這卻要自己用功，旁人無法代替你，更不是可以僥倖得到的。
      </p>
      <p>
      就拿「說食數寶」作一個譬喻，譬如一個人，光是說吃飯怎麼樣有營養，可是始終都不去吃，那麼，飯縱使真有營養，而說的人能得到它的營養嗎？所謂：
      </p>
      <p>
      終日數他寶，自無半錢分；
      於法不修行，其過亦如是。
      </p>
      <p>
      我們念菩薩，也是這個樣子，不能只是知道，或談論念菩薩的功德就算了，我們要能真正地念到一心不亂，甚至於，更進一步地連水流、風動，在我們的耳中聽來，也無不是稱誦菩薩洪名的聲音，所謂：
      </p>
      <p>
      有情無情，
      同演摩訶妙法。
      </p>
      <p>
      如果不能做到這一地步，不能一心不亂的話，那麼，各相雜陳，風吹就是呼呼聲，水流就是淙淙聲，就不能領略其中的妙處了。所以，我們應真實地念，而且在念的時候，不要摻入其他的妄念，這樣才能夠獲得其中的法益。
      </p>
      <p>
      其次，在打七時，應該重視打七的規矩，所謂「無規矩不能成方圓」。至於西樂園一向的規矩，就是不得喧嘩吵鬧，以免障礙他人的修行。
      </p>
      <p>
      在這七天的期間，我們又念〈大悲咒〉。這個〈大悲咒〉的功德，是不可思議的，如果沒有相當善根，就連「大悲咒」這三個字，也不能輕易聽到，現在大家不但能聞其名，而且更能受持讀誦，足見各位具大善根，曾於諸佛所種諸善根了，既然有大善根，那麼大家便不可輕易空過此生。</p>
      <p>
      記得最初在西樂園打七，居士十有八九是不會念〈大悲咒〉的，可是，到了今天，十個裏有八個以上都會念了，這就是諸位居士們顯著的進步。現在我說一段故事，來證明〈大悲咒〉的功德：</p>
      <p>
      在我們東北地方，有一個財主，他擁有很多田產，有一年的秋天，這個財主他親自隨著四、五輛大貨車，滿載著高粱到市上去賣。因為鄉間和城市的距離有一百五十多里路，所以，他在凌晨一時多，就趕車起程，可是在半途中，不幸遇賊，那財主見前面有賊，就念起〈大悲咒〉，說也奇怪，那幫土匪竟然像瞎子一樣，看不見他的車，於是乎，他們便安全渡過難關，這是我所見聞〈大悲咒〉的靈感之一。
      </p>
      <p>
      《大悲心陀羅尼經》上說：「誦持大悲咒者。能消諸難。遇火不焚。遇水不溺。」所以，我勸已經會念的居士，每日應最少誦持三遍，至於不會念的，也應趕快學。誦持〈大悲咒〉的功德，不但能退盜賊，更能消除百病，平諸魔難，所以我們應該誠心地誦持。
      </p>
      <p>
      在這開始打七的今日，法會的氣氛很好，很能鄭重其事，希望各位再接再勵，更圖精進！
      </p>

    `,
  },

];

export default serviceData;
