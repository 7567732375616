import blogOne from "../assets/images/news/img-1.jpg";
import cult01 from "../assets/images/news/1_Cultivation/Cult_03.jpg";
import cult02 from "../assets/images/news/1_Cultivation/Cult_04.jpg";
import cult03 from "../assets/images/news/1_Cultivation/Cult_05.jpg";
import cult04 from "../assets/images/news/1_Cultivation/Cult_06.jpg";
import cult05 from "../assets/images/news/1_Cultivation/Cult_07.jpg";
import cult06 from "../assets/images/news/1_Cultivation/Cult_08.jpg";
import cult07 from "../assets/images/news/1_Cultivation/Cult_09.jpg";
import cult08 from "../assets/images/news/1_Cultivation/Cult_10.jpg";
import cult09 from "../assets/images/news/1_Cultivation/Cult_11.jpg";
import cult10 from "../assets/images/news/1_Cultivation/Cult_12.jpg";
import cult11 from "../assets/images/news/1_Cultivation/Cult_13.jpg";
import cult12 from "../assets/images/news/1_Cultivation/Cult_14.jpg";
import cult13 from "../assets/images/news/1_Cultivation/Cult_15.jpg";
import cult14 from "../assets/images/news/1_Cultivation/Cult_16.jpg";
import cult15 from "../assets/images/news/1_Cultivation/Cult_17.jpg";
import cult16 from "../assets/images/news/1_Cultivation/Cult_18.jpg";
import cult17 from "../assets/images/news/1_Cultivation/Cult_19.jpg";
import cult18 from "../assets/images/news/1_Cultivation/Cult_20.jpg";
import cult19 from "../assets/images/news/1_Cultivation/Cult_21.jpg";
import cult20 from "../assets/images/news/1_Cultivation/Cult_22.jpg";
import cult21 from "../assets/images/news/1_Cultivation/Cult_23.jpg";
import cult22 from "../assets/images/news/1_Cultivation/Cult_24.jpg";
import cult23 from "../assets/images/news/1_Cultivation/Cult_25.jpg";
import cult24 from "../assets/images/news/1_Cultivation/Cult_26.jpg";
import cult25 from "../assets/images/news/1_Cultivation/Cult_27.jpg";
import cult26 from "../assets/images/news/1_Cultivation/Cult_28.jpg";
import cult27 from "../assets/images/news/1_Cultivation/Cult_29.jpg";
import cult28 from "../assets/images/news/1_Cultivation/Cult_30.jpg";
import cult29 from "../assets/images/news/1_Cultivation/Cult_31.jpg";
import cult30 from "../assets/images/news/1_Cultivation/Cult_32.jpg";
import cult31 from "../assets/images/news/1_Cultivation/Cult_33.jpg";
import cult32 from "../assets/images/news/1_Cultivation/Cult_34.jpg";
import cult33 from "../assets/images/news/1_Cultivation/Cult_35.jpg";
import cult34 from "../assets/images/news/1_Cultivation/Cult_36.jpg";
import cult35 from "../assets/images/news/1_Cultivation/Cult_37.jpg";
import cult36 from "../assets/images/news/1_Cultivation/Cult_38.jpg";
import cult37 from "../assets/images/news/1_Cultivation/Cult_39.jpg";
import cult38 from "../assets/images/news/1_Cultivation/Cult_40.jpg";
import cult39 from "../assets/images/news/1_Cultivation/Cult_41.jpg";
import cult40 from "../assets/images/news/1_Cultivation/Cult_42.jpg";
import cult41 from "../assets/images/news/1_Cultivation/Cult_43.jpg";
import cult42 from "../assets/images/news/1_Cultivation/Cult_44.jpg";
import cult43 from "../assets/images/news/1_Cultivation/Cult_45.jpg";


import blogTwo from "../assets/images/news/img-2.jpg";
import pat01 from "../assets/images/news/2_Patience/Pat_01.jpg";
import pat02 from "../assets/images/news/2_Patience/Pat_02.jpg";
import pat03 from "../assets/images/news/2_Patience/Pat_03.jpg";
import pat04 from "../assets/images/news/2_Patience/Pat_04.jpg";
import pat05 from "../assets/images/news/2_Patience/Pat_05.jpg";
import pat06 from "../assets/images/news/2_Patience/Pat_06.jpg";
import pat07 from "../assets/images/news/2_Patience/Pat_07.jpg";
import pat08 from "../assets/images/news/2_Patience/Pat_08.jpg";
import pat09 from "../assets/images/news/2_Patience/Pat_09.jpg";
import pat10 from "../assets/images/news/2_Patience/Pat_10.jpg";
import pat11 from "../assets/images/news/2_Patience/Pat_11.jpg";
import pat12 from "../assets/images/news/2_Patience/Pat_12.jpg";
import pat13 from "../assets/images/news/2_Patience/Pat_13.jpg";
import pat14 from "../assets/images/news/2_Patience/Pat_14.jpg";
import pat15 from "../assets/images/news/2_Patience/Pat_15.jpg";
import pat16 from "../assets/images/news/2_Patience/Pat_16.jpg";
import pat17 from "../assets/images/news/2_Patience/Pat_17.jpg";
import pat18 from "../assets/images/news/2_Patience/Pat_18.jpg";
import pat19 from "../assets/images/news/2_Patience/Pat_19.jpg";

import blogThird from "../assets/images/news/img-3.jpg";
import chan01 from "../assets/images/news/3_Chan/Chan01.jpg";
import chan02 from "../assets/images/news/3_Chan/Chan02.jpg";
import chan03 from "../assets/images/news/3_Chan/Chan03.jpg";
import chan04 from "../assets/images/news/3_Chan/Chan04.jpg";
import chan05 from "../assets/images/news/3_Chan/Chan05.jpg";
import chan06 from "../assets/images/news/3_Chan/Chan06.jpg";
import chan07 from "../assets/images/news/3_Chan/Chan07.jpg";
import chan08 from "../assets/images/news/3_Chan/Chan08.jpg";
import chan09 from "../assets/images/news/3_Chan/Chan09.jpg";
import chan10 from "../assets/images/news/3_Chan/Chan10.jpg";
import chan11 from "../assets/images/news/3_Chan/Chan11.jpg";
import chan12 from "../assets/images/news/3_Chan/Chan12.jpg";
import chan13 from "../assets/images/news/3_Chan/Chan13.jpg";
import chan14 from "../assets/images/news/3_Chan/Chan14.jpg";
import chan15 from "../assets/images/news/3_Chan/Chan15.jpg";
import chan16 from "../assets/images/news/3_Chan/Chan16.jpg";
import chan17 from "../assets/images/news/3_Chan/Chan17.jpg";
import chan18 from "../assets/images/news/3_Chan/Chan18.jpg";
import chan19 from "../assets/images/news/3_Chan/Chan19.jpg";
import chan20 from "../assets/images/news/3_Chan/Chan20.jpg";


import blogFour from "../assets/images/news/img-4.jpg";
import sangha01 from "../assets/images/news/4_Sangha/Sangha01.jpg";
import sangha02 from "../assets/images/news/4_Sangha/Sangha02.jpg";
import sangha03 from "../assets/images/news/4_Sangha/Sangha03.jpg";
import sangha04 from "../assets/images/news/4_Sangha/Sangha04.jpg";
import sangha05 from "../assets/images/news/4_Sangha/Sangha05.jpg";
import sangha06 from "../assets/images/news/4_Sangha/Sangha06.jpg";
import sangha07 from "../assets/images/news/4_Sangha/Sangha07.jpg";
import sangha08 from "../assets/images/news/4_Sangha/Sangha08.jpg";
import sangha09 from "../assets/images/news/4_Sangha/Sangha09.jpg";
import sangha10 from "../assets/images/news/4_Sangha/Sangha10.jpg";
import sangha11 from "../assets/images/news/4_Sangha/Sangha11.jpg";
import sangha12 from "../assets/images/news/4_Sangha/Sangha12.jpg";
import sangha13 from "../assets/images/news/4_Sangha/Sangha13.jpg";
import sangha14 from "../assets/images/news/4_Sangha/Sangha14.jpg";


const blogsData = [
  {
    id: 1,
    imageUrl: blogOne,
    titleEn: "Investigating Chan",
    titleZh: "參禪念佛",
    dateEn: "A Biographical Sketch of the Venerable Master Hsuan Hua",
    dateZh: "宣化上人簡傳",
    buttonEn: "Read More",
    buttonZh: "瀏覽更多",
    link: "#",
    contentEn: `
      <img src=${cult01} alt="Investigating Chan 1">
      <p>
      To cultivate the Way is to turn yourself around。 What does that mean？ It means to benefit others， and take a loss yourself。 It means to renounce one's self (the small self) in order to accomplish the greater good for all。       
      </p> 
      <img src=${cult02} alt="Investigating Chan 2">
      <p>
      Do not harbor animosity even towards the demons; treat them as good and wise advisors who aid you in cultivation。
      </p> 
      <img src=${cult03} alt="Investigating Chan 3">
      <p>
      Our varying degrees of false-thinking are due to the varying karma we have created in life after life throughout limitless eons。 Heavy karma results in abundant false-thoughts; light karma results in fewer false-thoughts。
      </p> 
      <img src=${cult04} alt="Investigating Chan 4">
      <p>
      Cultivators should cultivate the Way in order to end birth and death and cross over living beings - not for the sake of obtaining efficacious responses.
      </p>
      <img src=${cult05} alt="Investigating Chan 5">
      <p>
      Cultivators of the Way should return the light and reflect within at all times.  Do not seek outside， for the Way cannot be found there.  In our inherent nature， we have everything that we need.
      </p> 
      <img src=${cult06} alt="Investigating Chan 6">
      <p>
      When we first resolve to cultivate the Way， our biggest obstacle is sexual desire.  This remains the fundamental problem.
      </p> 
      <img src=${cult07} alt="Investigating Chan 7">
      <p>
      Cultivators of the Way!  Take heed!  Do not get into unwholesome relationships with anyone.  Otherwise， entangled and confused， you will surely fall.  
      </p>
      <img src=${cult08} alt="Investigating Chan 8">
      <p>
      We are fortunate to have these human bodies.  If we don't rely on our human bodies to cultivate now， what are we waiting for？If we wait until we lose our human bodies and then wish to cultivate， it will be too late.
      </p> 
      <img src=${cult09} alt="Investigating Chan 9">
      <p>
      Bring forth your true mind in studying the Buddhadharma.  Your every move and action， your every word and deed need to be true.  
      </p>             
      <img src=${cult10} alt="Investigating Chan 10">
      <p>
      You should conserve the resources of every Way-place. It is said， "Cherish all the properties of the Permanently Dwelling (monastery) as you would the pupils of your eyes.
      </p>
      <img src=${cult11} alt="Investigating Chan 11">
      <p>
      Neither loving nor hating anything is the Middle Way. We speak of cultivating the Way， but what is this Way that we cultivate? It is the Middle Way- treating everyone with equanimity， kindness and compassion. However， we need to be prudent in our actions， don't fall into the cage of love between a man and a woman.
      </p> 
      <img src=${cult12} alt="Investigating Chan 12">
      <p>
      Cultivating the Way today with the hope of attaining Buddhahood tomorrow is like expecting to dig a well with just one scoop of a shovel. That would be impossible. Cultivating the Way is like grinding an iron bar down to the size of a needle - after you exert sufficient effort， you will naturally achieve your goal. 
      </p> 
      <img src=${cult13} alt="Investigating Chan 13">
      <p>
      Before learning any mantras， you should first develop a proper mindfulness and a sincere intent. If your mind is not proper， then any mantra you learn will miss the mark. Only when you have proper mindfulness will you receive an efficacious response from learning mantras. 
      </p>
      <img src=${cult14} alt="Investigating Chan 14">
      <p>
      Ignorance has two accomplices or partners.   What are they？They are the desire for food and the desire for sex.  Both help ignorance commit a variety of bad deeds.
      </p> 
      <img src=${cult15} alt="Investigating Chan 15">
      <p>
      It's said that "When you try to be good， karmic debts will appear; when you attempt to accomplish Buddhahood， first you have to undergo the insults of the demons." If you do not aspire to be good， your karmic debts will not know where to look for you. The more you resolve to be good， the more your karmic debts will come looking for you， determined to claim what you owe. 
      </p>  
      <img src=${cult16} alt="Investigating Chan 16">
      <p>
      The difference between a Buddha and a demon lies in just a single thought. A Buddha has a kind and compassionate mind. A demon has a contentious mind. 
      </p>
      <img src=${cult17} alt="Investigating Chan 17">
      <p>
      Those who truly know cultivation do the work right in their daily activities， for every act， and every deed constitute cultivation. 
      </p> 
      <img src=${cult18} alt="Investigating Chan 18">
      <p>
      A cultivator should not sell or advertise his cultivation. Whoever sells his cultivation will fall under the spell of demons. 
      </p> 
      <img src=${cult19} alt="Investigating Chan 19">
      <p>
      Cultivators of the Way should， on all occasions， conceal their brightness and erase their tracks. They should not show off their extraordinary qualities. 
      </p>
      <img src=${cult20} alt="Investigating Chan 20">
      <p>
      Our minds always exist in someone else's place instead of our own， meaning that we are physically present but mentally absent.
      </p> 
      <img src=${cult21} alt="Investigating Chan 21">
      <p>
      Lecturing on sutras is cultivating wisdom. Meditating is cultivating Samadhi. Refusing to speak casually and irresponsibly is cultivating precepts.
      </p>  
      <img src=${cult22} alt="Investigating Chan 22">
      <p>
      It is said， "Affliction is Bodhi." If you know how to do it， you can turn affliction into Bodhi. If you do not know how， Bodhi can turn into affliction.
      </p>
      <img src=${cult23} alt="Investigating Chan 23">
      <p>
      Cultivation is "training to play the fool." Training to play the fool means to appear stupid and inept. In cultivation， the more stupid you are， the better. If you are foolish to the point of knowing nothing， you will also do no more false-thinking.
      </p> 
      <img src=${cult24} alt="Investigating Chan 24">
      <p>
      Ignorance means "not understanding." The root of ignorance is desire.
      </p> 
      <img src=${cult25} alt="Investigating Chan 25">
      <p>
      The main purpose of cultivation is to end birth and death， not to obtain efficacious responses.
      </p>
      <img src=${cult26} alt="Investigating Chan 26">
      <p>
      A cultivator has to protect his purity like the way he protects his eyes， which cannot tolerate a single grain of sand.
      </p> 
      <img src=${cult27} alt="Investigating Chan 27">
      <p>
      Gambling can create karmic offenses; making offerings can accumulate merit and virtue; meditation can eliminate delution and increase wisdom.
      </p>  
      <img src=${cult28} alt="Investigating Chan 28">
      <p>
      When we cultivate， we should watch our words when we live with others， and guard our minds when we are sitting alone. When a cultivator is in the presence of others， he should not talk too much. When he is alone， he should guard his mind from selfish and idle thoughts. By doing so， over time， a cultivator will be able to see his own nature with single-minded concentration.
      </p>
      <img src=${cult29} alt="Investigating Chan 29">
      <p>
      To endure suffering puts an end to suffering once it is gone， but to enjoy blessings uses up blessings once they are gone.
      </p> 
      <img src=${cult30} alt="Investigating Chan 30">
      <p>
      A true cultivator controls his own actions and behavior， and doesn't stray from his mindfulness whether he is walking， standing， sitting， or reclining. Don't be a mirror which only can reflect others and not yourself.
      </p> 
      <img src=${cult31} alt="Investigating Chan 31">
      <p>
      Whoever has attachments has the "mind of a human" but whoever lives without attachments has the "mind of the Way."
      </p>
      <img src=${cult32} alt="Investigating Chan 32">
      <p>
      "Someone who wants to escape death must first become a living dead-person." That means treating yourself as if you were already dead - with no greed， hatred， and delusion.
      </p> 
      <img src=${cult33} alt="Investigating Chan 33">
      <p>
      Whoever studies Buddhism must make vows， because the power of vows can spur you to advance toward the proper Way and prevent you from going astray. But if vows are not put into practice， they're just like blossoms on the trees that don't bear fruits， they're useless.
      </p>  
      <img src=${cult34} alt="Investigating Chan 34">
      <p>
      You can use your tongue to accumulate merit and virtue by speaking the Dharma. You can also use your tongue to create offenses by gossiping. If instead of speaking the Dharma， you gossip or speak frivolously， then you have created twelve thousand offenses.
      </p>
      <img src=${cult35} alt="Investigating Chan 35">
      <p>
      A cultivator will naturally evoke efficacious responses， if in all circumstances he always uses attitudes of kindness， compassion， joy， and giving towards all people， no matter whether they are young， old， honorable or humble.
      </p>  
      <img src=${cult36} alt="Investigating Chan 36">
      <p>
      Cultivating the Way is nothing more than the practice of honesty and sincerity. As the saying goes， "If the mind is sincere， everything will be auspicious."
      </p>  
      <img src=${cult37} alt="Investigating Chan 37">
      <p>
      We should try to do good deeds constantly during our time here in this word. Each breath and every bit of strength we have should be channeled into performing good deeds and accruing merit and virtue. In this life， do not draw on the good roots you planted in past lives without cultivating more， and thus exhaust all the blessings you earned.
      </p> 
      <img src=${cult38} alt="Investigating Chan 38">
      <p>
      The Buddha can turn all living beings into Dharma vessels. Thus the saying: "Borrow the false to cultivate the genuine." The "stinking skin bag" is just our temporary residence so we can cultivate the Way. This is the meaning of "borrowing the false body (physical body) to cultivate the genuine body (Dharma body)."
      </p> 
      <img src=${cult39} alt="Investigating Chan 39">
      <p>
      Someone who is very greedy， insatiably greedy， in the future will fall into the hells. Someone who is very hateful， constantly flaring up day and night， will become a hungry ghost.， Someone who is very stupid， always doing foolish deeds， will become an animal.
      </p> 
      <img src=${cult40} alt="Investigating Chan 40">
      <p>
      The greatest shortcoming of living beings is foolish love. Day in any day out， people live in their blind passion， unable to let go for a single moment. If they can only channel their lust into learning the Buddhadharma， never forgetting to study the Buddhadharma in every moment， then they could quickly attain Buddhahood.
      </p>  
      <img src=${cult41} alt="Investigating Chan 41">
      <p>
      One who is truly enlightened would not proclaim that he has attained enlightenment. When Sages appear in the world， they definitely do not reveal their true identity. For this reason， anybody who proclaims himself to be a Buddha or Bodhisattva is a twisted demon.
      </p>  
      <img src=${cult42} alt="Investigating Chan 42">
      <p>
      People of noble and virtuous conduct are limited neither by social status nor age. With quiet speech and silent actions， they just naturally project an awesome character that people respect. Their awesomeness does not evoke fear in others， for fear would make people stay away from them. Rather， this is the type of awesomeness that inspires respect， not fear.
      </p>  
      <img src=${cult43} alt="Investigating Chan 43">
      <p>
      Everybody has three afflicted thieves， namely: greed， hatred， and delusion. There is no need to annihilate them; transform them into Bodhi seeds instead.
      </p>  
    `,
    contentZh: `
      <img src=${cult01} alt="參禪念佛 1">
      <p>
      修道，就是要「倒過來」。什麼意思呢？即是好事給他人，壞事予自己;捨棄小我，完成大我。
      </p>
      <img src=${cult02} alt="參禪念佛 2">
      <p>
      對魔不要有敵對的心理，當做助道的善知識。
      </p>
      <img src=${cult03} alt="參禪念佛 3">
      <p>
      我們從無量劫以來，生生世世所造的業不同，所以妄想也不同。業重妄想多，業輕妄想少，成為正比例。
      </p>
      <img src=${cult04} alt="Investigating Chan 4">
      <p>
      修行人是為了生死，為度眾生而修道，不是為求感應而修道。
      </p>
      <img src=${cult05} alt="Investigating Chan 5">
      <p>
      修道人，時時要迴光返照，不要向外馳求，在外邊是找不到的，在自性裏，應有盡有，一概俱全。
      </p> 
      <img src=${cult06} alt="Investigating Chan 6">
      <p>
      我們初發心修行，最障礙我們用功的， 就是男貪女，女貪男的淫欲心，這是最根本的問題。
      </p> 
      <img src=${cult07} alt="Investigating Chan 7">
      <p>
      修道人切記！ 不要和任何人有污染的因緣，這樣會糾纏不清，令你墮落。
      </p>
      <img src=${cult08} alt="Investigating Chan 8">
      <p>
      我們現在得到人身，若不藉著人身來修行，還等什麼？等把人身丢了，再想修行，那時來不及的。
      </p> 
      <img src=${cult09} alt="Investigating Chan 9">
      <p>
      學佛法要拿出真心，一舉一動，一言一 行，都要往真的去做。
      </p>             
      <img src=${cult10} alt="Investigating Chan 10">
      <p>
      在道場裏要節省一切物質，所謂「愛惜常住物，如護眼中珠。」
      </p>
      <img src=${cult11} alt="Investigating Chan 11">
      <p>
      不愛不增為中道。修道，修什麼道? 就是修這個中道。對誰都要平等相待，慈悲為懷，但要謹慎行事，不可落在情愛樊籠中。
      </p> 
      <img src=${cult12} alt="Investigating Chan 12">
      <p>
      今天修道，明天成佛，一鍬就想挖成井，沒有這種道理；修行是「鐵杵磨繡針，功到自然成，」
      </p> 
      <img src=${cult13} alt="Investigating Chan 13">
      <p>
      學咒要先正心誠意。若心不正學什麼咒都是邪的；心正了，學咒才有感應。
      </p>
      <img src=${cult14} alt="Investigating Chan 14">
      <p>
      無明有兩個幫兇，兩個夥計，究竟是什麼呢？就是食與色。一個食欲，一個色欲，這兩個幫著無明做種種壞事。
      </p> 
      <img src=${cult15} alt="Investigating Chan 15">
      <p>
      「要學好，冤孽找；要成佛，先受魔。」若不想學好，則冤孽不會來找；愈想學好，冤孽愈來找，欲把債務算清。
      </p>  
      <img src=${cult16} alt="Investigating Chan 16">
      <p>
      佛、魔都是一念之間。佛有慈悲心，魔有勝負心。
      </p>
      <img src=${cult17} alt="Investigating Chan 17">
      <p>
      若真懂修行的人，起居動作都是修行。
      </p> 
      <img src=${cult18} alt="Investigating Chan 18">
      <p>
      修行不可各處賣修行、賣廣告。一賣修行，就會著魔。
      </p> 
      <img src=${cult19} alt="Investigating Chan 19">
      <p>
      我們修道人，無論在什麼場合，應該韜光晦跡，不可光芒外露。
      </p>
      <img src=${cult20} alt="Investigating Chan 20">
      <p>
      「我們的心，都是「他」在，而不「自」 在；他在就是人雖在，可是心不在。
      </p> 
      <img src=${cult21} alt="Investigating Chan 21">
      <p>
      講經就是修慧，打坐就是修定，不亂講話就是修戒。
      </p>  
      <img src=${cult22} alt="Investigating Chan 22">
      <p>
      「煩惱即菩提」。若會用，煩惱就是菩提；若是不會用，菩提變煩惱。
      </p>
      <img src=${cult23} alt="Investigating Chan 23">
      <p>
      修行就是要「養拙」，「拙」就是很笨的意思。修行要越笨越好，笨得什麼也不知，一點妄想也無。
      </p> 
      <img src=${cult24} alt="Investigating Chan 24">
      <p>
      無明，即是不明白。無明的根本，是愛欲 。
      </p> 
      <img src=${cult25} alt="Investigating Chan 25">
      <p>
      修道的主要目的為了生脱死，不是為求感應而修道。
      </p>
      <img src=${cult26} alt="Investigating Chan 26">
      <p>
      修道人，好像眼睛那樣清淨，不能容納一粒沙子。
      </p> 
      <img src=${cult27} alt="Investigating Chan 27">
      <p>
      賭錢可造罪業，布施能積功德，坐禪能去愚生智。
      </p>  
      <img src=${cult28} alt="Investigating Chan 28">
      <p>
      我們修行，群居守口，獨坐防心，跟大眾一起時，不要講太多話，自己一人時，要提防私心妄想，久而久之，能專一明心。
      </p>
      <img src=${cult29} alt="Investigating Chan 29">
      <p>
      吃苦是了苦，享福是消福。
      </p> 
      <img src=${cult30} alt="Investigating Chan 30">
      <p>
      真正修道人，「舉動行爲管自己，行住坐臥不離家。」不要做鏡子，專照人家，不照自己。
      </p> 
      <img src=${cult31} alt="Investigating Chan 31">
      <p>
      有所執著，就是人心；無所執著，就是道心。
      </p>
      <img src=${cult32} alt="Investigating Chan 32">
      <p>
      欲要人不死，先做活死人。現在把自己當做死了一樣，也就是不貪、不瞋、不痴。
      </p> 
      <img src=${cult33} alt="Investigating Chan 33">
      <p>
      學佛的人一定要發願，願力能鞭策自己向正道邁進，不會誤入歧途，發願不實行好像開完花不結果的樹，沒有用處。
      </p>  
      <img src=${cult34} alt="Investigating Chan 34">
      <p>
      舌有說法的功德，也有說是、說非的罪過；若是不說法，而說是非，或說邪言邪語，那就是一萬二千的罪過。
      </p>
      <img src=${cult35} alt="Investigating Chan 35">
      <p>
      修道人無論對內、對外、對人，不論長幼尊卑，都常存慈悲喜捨的心，自然能生感應。
      </p>  
      <img src=${cult36} alt="Investigating Chan 36">
      <p>
      修道就是修個誠實，所謂「心誠則靈」。
      </p>  
      <img src=${cult37} alt="Investigating Chan 37">
      <p>
      我們人生在世，要即時行善。有一口氣，有一點力量就要行善積德，不要僅憑著前生所種下來的善根，享盡今生的福報。
      </p> 
      <img src=${cult38} alt="Investigating Chan 38">
      <p>
      佛能令世間眾生都成法器，所謂「借假修真」臭皮囊是暫時的房子。我們住在房子裡修道，這是借假身（色身）修真身（法身）。
      </p> 
      <img src=${cult39} alt="Investigating Chan 39">
      <p>
      要是貪心重，貪得無厭，將來就會墮地獄；你要瞋心重，一天到晚盡發脾氣，就會變餓鬼；要是痴心重，胡作非為，就會變畜牲。
      </p> 
      <img src=${cult40} alt="Investigating Chan 40">
      <p>
      眾生最大毛病便是痴愛，日夜在痴愛中，時刻不能放下。如果把好色之心放在學佛上，時刻不忘學佛，那就很快能成佛。
      </p>  
      <img src=${cult41} alt="Investigating Chan 41">
      <p>
      真正開悟的人，不會說自己已經開悟。因聖人應世，絕不會親自洩漏真相，所以凡是稱自己是佛菩薩的人，都是邪魔。
      </p>  
      <img src=${cult42} alt="Investigating Chan 42">
      <p>
      一個很有德行的人，不在地位與年齡。 他語默動靜，自然流露出令人敬畏的品格，這種畏，不是懼怕，懼怕就令人遠離他；而他之令人畏者，是尊敬之心， 不是恐懼之心。
      </p>  
      <img src=${cult43} alt="Investigating Chan 43">
      <p>
      人人都有三個煩惱賊，它們的名字叫貪瞋痴。我們不要殺滅它們，要把它們轉變過來成為菩提種。
      </p>  
    `,
  },














  {
    id: 2,
    imageUrl: blogTwo,
    titleEn: "Upholding the Precepts, Being Patient in Difficult Circumstances",
    titleZh: "持戒忍辱",
    dateEn: "A Biographical Sketch of the Venerable Master Hsuan Hua",
    dateZh: "宣化上人簡傳",
    buttonEn: "Read More",
    buttonZh: "瀏覽更多",
    link: "#",
    contentEn: `
      <img src=${pat01} alt="Upholding the Precepts 1">
      <p>
      A cultivator cultivates the mark of no-self. If you are free of the mark of self， you can endure everything. When states manifest， your mind will not be influenced. You will see yourself as empty space.
      </p>
      <img src=${pat02} alt="Upholding the Precepts 2">
      <p>
      To become a Buddha is not so easy! If you don't cut off your sexual desire yet still want to become a Buddha - that is absolutely impossible. There's no such principle in this world. Nowadays， most people are greedy for lucky breaks and shortcuts. We get attracted easily to the mysterious and fall into the demon's trap.
      </p>
      <img src=${pat03} alt="Upholding the Precepts 3">
      <p>
      Why do people lie? Because they are afraid of losing any benefit to themselves， afraid of being at a disadvantage. 
      </p>
      <img src=${pat04} alt="Upholding the Precepts 4">
      <p>
      If one breaks the precept against sexual misconduct， then it's easy to break the precept against killing， stealing and lying. Therefore， breaking the precept that prohibits sexual misconduct also includes the breaking of the precepts against killing， stealing， and lying.
      </p>
      <img src=${pat05} alt="Upholding the Precepts 5">
      <p>
      By upholding the Five Precepts and practicing the Ten Good Deeds， you will be born into the human and the deva (heavenly) realms. If you have an attitude of greed， hatred and delusion， then you may fall into the three evil paths.
      </p>
      <img src=${pat06} alt="Upholding the Precepts 6">
      <p>
      If you understand the precepts， then you can penetrate the entire Buddhadharma. If you don't understand the precepts， then you're like a cloud floating in the sky， lacking any foundation.
      </p>
      <img src=${pat07} alt="Upholding the Precepts 7">
      <p>
      All precepts share one basis - it is not being selfish.
      </p>
      <img src=${pat08} alt="Upholding the Precepts 8">
      <p>
      You should vigorously uphold precepts， even in places where people can't see you. You should not be vigorous only in front of people， or uphold precepts only in front of people. Instead， you should forge ahead vigorously and uphold precepts when you are all by yourself.
      </p>
      <img src=${pat09} alt="Upholding the Precepts 9">
      <p>
      Studying the Buddhadharma is learning not to trouble or harm others. Therefore， Buddhists must eat vegetarian food because eating meat harms other lives.
      </p>
      <img src=${pat10} alt="Upholding the Precepts 10">
      <p>
      Eating a vegetarian diet requires that you suffer a loss when you are still alive， because you cannot satisfy your palate with delicious food. But if you are not a vegetarian and eat a lot of meat， after your death you will have to settle your accounts before the King of Hell. As my conscience dictates， I am telling you the truth. If we are not greedy for our desire to satisfy our taste buds and greedy for pleasure， we won't be greatly fooled after we die.
      </p>
      <img src=${pat11} alt="Upholding the Precepts 11">
      <p>
      If someone who studies Buddhadharma does not uphold precepts， that person is like a bottle with a hole in the bottom. The water leaks out as soon as the bottle is filled. If one can uphold precepts， one will gradually obtain a state free of outflows (Anasrava).
      </p>
      <img src=${pat12} alt="Upholding the Precepts 12">
      <p>
      No matter what Dharma-door you practice， as long as you have patience， you will attain achievement. If you don't have patience then you can't cultivate any Dharma doors to successfully.
      </p>
      <img src=${pat13} alt="Upholding the Precepts 13">
      <p>
      What Way do the left-home people cultivate? They cultivate the Way of patience.
      </p>
      <img src=${pat14} alt="Upholding the Precepts 14">
      <p>
      “Patience is a priceless gem，Which few know how to mine， But once you've truly mastered it， Then everything works out fine.”
      </p>
      <img src=${pat15} alt="Upholding the Precepts 15">
      <p>
      A cultivator has to endure the things that others cannot bear， renounce the matters that others cannot give up， eat the foods that others cannot swallow， and wear the garments that others cannot put on. In brief， a cultivator ought to bear what others cannot bear.
      </p>
      <img src=${pat16} alt="Upholding the Precepts 16">
      <p>
      In cultivating the Way， the most important thing is not to fight. No fighting means not to quarrel over people's good points and shortcomings， nor gossip about others' rights and wrongs.
      </p>
      <img src=${pat17} alt="Upholding the Precepts 17">
      <p>
      Do not be greedy for anything at all. Know contentment and be patient. This is the unsurpassed wonderful Dharma that is， alas， neglected by all. By not fighting and not being greedy one will have boundless blessings and long life. If you are contentious， greedy， and always stirring up trouble， then you will incur many karmic offenses. Even if you wanted to leave the Triple Realm there would be no way for you to do so.
      </p>
      <img src=${pat18} alt="Upholding the Precepts 18">
      <p>
      All the various kinds of precepts are aimed at teaching people to follow rules. People who follow rules can help maintain order in the society and resolve the problems faced by humankind. Thus， the moral precepts are the basis for world peace.
      </p>
      <img src=${pat19} alt="Upholding the Precepts 19">
      <p>
      A cultivator cultivates the mark of no-self.
      </p>
    `,
    contentZh: `
      <img src=${pat01} alt="持戒忍辱 1">
      <p>
      修行人，就是修無我相。如無我相，一切能忍受，境界來了，也不動心。自己把自己看成虛空一樣
      </p>
      <img src=${pat02} alt="持戒忍辱 2">
      <p>
      成佛那有那麼容易！既不斷淫，又想成佛，那是絕不可能的事，天下間無此道理。今人多數貪僥倖、貪捷徑，一聽到什麼玄妙法，便被迷惑而陷入魔網。
      </p>
      <img src=${pat03} alt="持戒忍辱 3">
      <p>
      人為什麼打妄語呢？因為他怕自己的利益失去，怕吃虧。
      </p>
      <img src=${pat04} alt="持戒忍辱 4">
      <p>
      若犯淫戒，就容易犯殺戒，也容易犯偷戒，妄語戒。因此犯淫戒，便是殺盜淫妄都包括了。
      </p>
      <img src=${pat05} alt="持戒忍辱 5">
      <p>
      持五戒行十善，便生人天之界，有了貪瞋痴之心，便墮三惡道。 
      </p>
      <img src=${pat06} alt="持戒忍辱 6">
      <p>
      你若懂戒律，對一切佛法都能深入； 你若不懂戒律，就像虛空中的雲一樣， 浮浮蕩蕩，一點根基也沒有。
      </p>
      <img src=${pat07} alt="持戒忍辱 7">
      <p>
      戒律的根本只有一個，就是不自私。
      </p>
      <img src=${pat08} alt="持戒忍辱 8">
      <p>
      精勤持戒要注意在人不見的地方，不是在人前精進，或人前持戒；而是要在獨自一人的時候，一人的地方，精勤持戒。
      </p>
      <img src=${pat09} alt="持戒忍辱 9">
      <p>
      我們學佛就是學不惱害別人。故佛教徒要吃素，因為吃肉會傷害其他眾生的生命。
      </p>
      <img src=${pat10} alt="持戒忍辱 10">
      <p>
      吃齋是活著吃虧，因為未能滿足美食之欲，不能享口福；可是若不吃齋，吃 了很多肉，死後便要到地府算帳。我是憑良心向你講真話，大家不要貪口欲、 貪享受，死後就不會上大當。
      </p>
      <img src=${pat11} alt="持戒忍辱 11">
      <p>
      學佛的人如果不守戒，就等於瓶子底下有了洞，水一裝進去就漏掉；要是能守戒，久而久之就會成為無漏。
      </p>
      <img src=${pat12} alt="持戒忍辱 12">
      <p>
      無論什麼法門，只要你有忍耐心，都會有所成就。你要是沒有忍，什麼法門也修不了。
      </p>
      <img src=${pat13} alt="持戒忍辱 13">
      <p>
      出家修什麼道？就是修忍辱的道。
      </p>
      <img src=${pat14} alt="持戒忍辱 14">
      <p>
      忍是無價寶，人人使不好， 若能會用它，萬事都能了。
      </p>
      <img src=${pat15} alt="持戒忍辱 15">
      <p>
      修道人，要忍人所不能忍的事，要讓人所不讓的物，要吃人所不能吃的飯，要穿人所不能穿的衣。總而言之，需要受人所不能受的。
      </p>
      <img src=${pat16} alt="持戒忍辱 16">
      <p>
      我們在修道的時候，最重要的是「不 爭」；不爭是不和任何人爭長論短，爭是爭非。
      </p>
      <img src=${pat17} alt="持戒忍辱 17">
      <p>
      我們無論什麼也不要貪，要知足，要能忍，但這個無上妙法，人人都把它忽略了。所以不爭、不貪就能福壽無邊；你要是爭、貪、攪、擾，就罪孽不少，要想出離三界，也是無有是處的。
      </p>
      <img src=${pat18} alt="持戒忍辱 18">
      <p>
      種種的戒律，無非教人守規矩。能守規矩，就能令社會安定秩序，能令人群沒有一切的麻煩。所以戒律是安定世界的一個基礎。
      </p>
      <img src=${pat19} alt="持戒忍辱 19">
      <p>
      修行人，就是修無我相。 
      </p>
    `,
  },









  {
    id: 3,
    imageUrl: blogThird,
    titleEn: "Investigating Chan, Reciting the Buddha's Name",
    titleZh: "參禪念佛",
    dateEn: "A Biographical Sketch of the Venerable Master Hsuan Hua",
    dateZh: "宣化上人簡傳",
    buttonEn: "Read More",
    buttonZh: "瀏覽更多",
    link: "#",
    contentEn: `
      <img src=${chan01} alt="Investigating Chan, Reciting the Buddha's Name 1">
      <p>
      Why is it that we cannot recognized our original face？It’s because we haven’t removed our mark of self and selfishness. 
      </p>
      <img src=${chan02} alt="Investigating Chan, Reciting the Buddha's Name 2">
      <p>
      The word "investigate in "investigating Chan" means to contemplate.  Contemplate what? Prajna. This contemplation teaches you to have constant awareness of yourself， instead of others. Contemplate whether you are really present or not.
      </p>
      <img src=${chan03} alt="Investigating Chan, Reciting the Buddha's Name 3">
      <p>
      People who investigate Chan must first recognize the basic problem clearly. What problem? Our bad habits and faults. Participating in Chan session is to rid ourselves of bad habits and faults.
      </p>
      <img src=${chan04} alt="Investigating Chan, Reciting the Buddha's Name 4">
      <p>
      In the Chan hall， everybody investigates Chan and meditates. This is a test to see who can pass and attain Buddhahood. How do you pass？ Inside， there are no body and mind; outside， there is no world.
      </p>
      <img src=${chan05} alt="Investigating Chan, Reciting the Buddha's Name 5">
      <p>
      Many people who investigate Chan make two mistakes: one is having an unsettled state of mind， the other is drowsiness. These individuals are either having idle thoughts or falling asleep during their meditation.
      </p>
      <img src=${chan06} alt="Investigating Chan, Reciting the Buddha's Name 6">
      <p>
      To investigate Chan， one must have patience and perseverance. The secret to investigating Chan is patience. Even if you can't bear it anymore， you still must persevere. Once you have endured to the extreme， then everything will be interpenetrating and you will become enlightened.
      </p>
      <img src=${chan07} alt="Investigating Chan, Reciting the Buddha's Name 7">
      <p>
      When your investigation of Chan reaches maturity， not only will you have no false thinking， you'll also have less anger， your afflictions will decrease， your character will improve， and your capacity for tolerance will expand.
      </p>
      <img src=${chan08} alt="Investigating Chan, Reciting the Buddha's Name 8">
      <p>
      Why don't we have any response in the Way? It's because our wild minds do not rest.
      </p>
      <img src=${chan09} alt="Investigating Chan, Reciting the Buddha's Name 9">
      <p>
      To investigate Chan， one must have patience， for that is your means to enlightenment.
      </p>
      <img src=${chan10} alt="Investigating Chan, Reciting the Buddha's Name 10">
      <p>
      We have the opportunity to become enlightened when we investigate Chan. When our true nature appears， it's like when spring returns to the earth and the myriad things start to grow.
      </p>
      <img src=${chan11} alt="Investigating Chan, Reciting the Buddha's Name 11">
      <p>
      A person who truly investigates Chan is a person who truly recites the Buddha's name; a person who truly recites the Buddha's name is also a person who truly investigates Chan. A person who truly upholds the precepts is also a person who truly investigates Chan.
      </p>
      <img src=${chan12} alt="Investigating Chan, Reciting the Buddha's Name 12">
      <p>
      What is the "real me?" It is our inherent nature and the attainment of Buddhahood. Only when one attains Buddhahood， can one find the "real me." Before that， everything is false.
      </p>
      <img src=${chan13} alt="Investigating Chan, Reciting the Buddha's Name 13">
      <p>
      Whoever does not have any defiled attachments is a Buddha. Whoever has defiled attachments is a living being. What is a defiled attachment? To put it simply， it is whatever one cannot see beyond and let go of， and what causes one to constantly have idle thoughts.
      </p>
      <img src=${chan14} alt="Investigating Chan, Reciting the Buddha's Name 14">
      <p>
      When you apply exacting effort to your cultivation， food and clothing become secondary. How much the more in regard to other external objects; you should let go of them.
      </p>
      <img src=${chan15} alt="Investigating Chan, Reciting the Buddha's Name 15">
      <p>
      Cultivation is not limited to practicing Chan meditation and reciting Sutras. Instead， cultivation is done at all times and places. Do not have such a discriminating mind; do not contend for power; do not vie for leadership to supervise other people; do not show off before the Master.
      </p>
      <img src=${chan16} alt="Investigating Chan, Reciting the Buddha's Name 16">
      <p>
      The essential quality of the Way is concentration; that of a general is his strategic skill， not his courage; that of an army is its quality， not its size.
      </p>
      <img src=${chan17} alt="Investigating Chan, Reciting the Buddha's Name 17">
      <p>
      Sincerely reciting the Buddha's name is to constantly concentrate one's mind on the recitation without any false thinking or desire to eat and drink. Reciting to the point where everything is forgotten is true mindfulness of the Buddha.
      </p>
      <img src=${chan18} alt="Investigating Chan, Reciting the Buddha's Name 18">
      <p>
      When you are mindful of the Buddha， the Buddha will also be mindful of you. It is a bit like sending a telegram to Amitabha Buddha. This is known as the "intertwining of the response and the Way." If you are not mindful of the Buddha， he will not receive your message. Therefore， it is imperative that you uphold the Buddha's name.
      </p>
      <img src=${chan19} alt="Investigating Chan, Reciting the Buddha's Name 19">
      <p>
      In reciting mantras， one should so recite that the mantra flows out of one's mind and returns to one's mind. The mantra mind and the mind mantra-the mantra and the mind are united in sound after sound and are never apart from each other. One is reciting， yet not reciting; one is not reciting， yet constantly reciting.
      </p>
      <img src=${chan20} alt="Investigating Chan, Reciting the Buddha's Name 20">
      <p>
      When you venerate the Buddha， and the Buddha accepts your veneration， you will increase your blessings and wisdom. That is to say that bowing to the Buddha is an influence， and his receiving of the veneration is a response. That is the "intertwining of the Way."
      </p>
    `,
    contentZh: `
      <img src=${chan01} alt="參禪念佛 1">
      <p>
      我們為甚麼不認識本來面目？因為「我相」沒有去掉，「自私心」沒有去掉。
      </p>
      <img src=${chan02} alt="參禪念佛 2">
      <p>
      參禪的參，就是觀。觀什麼？觀照般若。教你念茲在茲觀自在，不是觀他在。觀觀自己在不在？
      </p>
      <img src=${chan03} alt="參禪念佛 3">
      <p>
      參禪的人要把根本問題認識清楚。什麼問題？就是習氣毛病。我們打禪七，就是打掉惡習氣、壞毛病。
      </p>
      <img src=${chan04} alt="參禪念佛 4">
      <p>
      大家在禪堂，參禪打坐，就是考試。看誰能考上佛的果位。怎樣才能考上呢？就要內無身心，外無世界。
      </p>
      <img src=${chan05} alt="參禪念佛 5">
      <p>
      一般人參禪，犯了兩種毛病：一為掉舉，一為昏沈。不是打妄想，就是在參禪中睡覺。
      </p>
      <img src=${chan06} alt="參禪念佛 6">
      <p>
      參禪要有忍耐心，長遠心。參禪的祕訣，就是忍，忍不住也要忍；忍到極點，豁然貫通，明朗開悟。
      </p>
      <img src=${chan07} alt="參禪念佛 7">
      <p>
      參禪參到火候時，不但沒有妄想，而且脾氣也小了，煩惱也少了，人品高了， 氣度也大了。
      </p>
      <img src=${chan08} alt="參禪念佛 8">
      <p>
      我們為什麼和道不相應呢？就因為狂心沒有休息。
      </p>
      <img src=${chan09} alt="參禪念佛 9">
      <p>
      參禪要有忍耐心，那是開悟的本錢。
      </p>
      <img src=${chan10} alt="參禪念佛 10">
      <p>
      我們參禪，就有機會開悟，自性光明現前，猶如春回大地，百物滋生。
      </p>
      <img src=${chan11} alt="參禪念佛 11">
      <p>
      真正參禪的人就是真正念佛；真正念佛的人也就是真正參禪。真正持戒的人也就是真正參禪。
      </p>
      <img src=${chan12} alt="參禪念佛 12">
      <p>
      真我是什麼？就是自性，就是成佛，成佛才是真我，沒有成佛以前，都是假的。
      </p>
      <img src=${chan13} alt="參禪念佛 13">
      <p>
      誰沒有染著，誰就是佛；誰有染著，誰就是眾生。什麼是染著？淺而言之，就是看不破，放不下，時時刻刻在打妄想。
      </p>
      <img src=${chan14} alt="參禪念佛 14">
      <p>
      用功用到恰當的時候，就食不知食，穿不知穿，何況身外之物，更應該放下。
      </p>
      <img src=${chan15} alt="參禪念佛 15">
      <p>
      修行不是只限於參禪誦經，而是隨時隨地在修行。不要有那麼多的分別心，更不要爭權奪利，爭做領袖支配別人， 在師父前求表現。
      </p>
      <img src=${chan16} alt="參禪念佛 16">
      <p>
      道在專一，將在謀而不在勇，兵在精而不在多。
      </p>
      <img src=${chan17} alt="參禪念佛 17">
      <p>
      老實念佛就是念茲在茲，什麼妄想也無，也不想吃東西或喝茶，什麼皆忘， 這才是老實念佛
      </p>
      <img src=${chan18} alt="參禪念佛 18">
      <p>
      你念佛，佛也就念你，就如同給阿彌陀佛打一個無線電報，這叫感應道交，你不念他，他就收不到，所以必須要持名。
      </p>
      <img src=${chan19} alt="參禪念佛 19">
      <p>
      念咒要念到咒從心中念出，咒又能回到心裡，咒心，心咒，咒和心聲生在一 起，不會分離，念而不念，不念而念。
      </p>
      <img src=${chan20} alt="參禪念佛 20">
      <p>
      你禮佛，佛受禮，能增加你的福慧。 也就是說拜佛是感，受禮是應，這樣就是道交。 
      </p>
    `,
  },

  {
    id: 4,
    imageUrl: blogFour,
    titleEn: "The cultivation of the Sangha members",
    titleZh: "出家行",
    dateEn: "A Biographical Sketch of the Venerable Master Hsuan Hua",
    dateZh: "宣化上人簡傳",
    buttonEn: "Read More",
    buttonZh: "瀏覽更多",
    link: "#",
    contentEn: `
      <img src=${sangha01} alt="The cultivation of the Sangha members 1">
      <p>
      The Sangha is attained when four or more who have given up the householder's life live together in harmony and peace， without conflicts or attachments. A single left-home person by himself cannot be called a Sangha.
      </p>
      <img src=${sangha02} alt="The cultivation of the Sangha members 2">
      <p>
      Those who seek transcendental Dharma need to leave behind the worldly Dharma. If not， it is like standing in two boats， wanting to go north and south at the same time； such a deed is impossible.
      </p>
      <img src=${sangha03} alt="The cultivation of the Sangha members 3">
      <p>
      Since ancient times， all eminent Dharma Masters and the greatly virtuous ones obtained their enlightenment by ascetic practices and diligent cultivation. You can search through the Tripitaka and not find even a single Patriarch who attained enlightenment through pursuit of pleasure.
      </p>
      <img src=${sangha04} alt="The cultivation of the Sangha members 4">
      <p>
      What are the criteria for choosing an Abbot? He must be even-tempered， congenial and affable. He should practice democracy， and not use his power to oppress anyone. He must inspire respect.
      </p>
      <img src=${sangha05} alt="The cultivation of the Sangha members 5">
      <p>
      Sangha members may accept offerings， but should not be greedy for offerings. Only by not being greedy for offerings， can they be like true disciples of the Buddha.
      </p>
      <img src=${sangha06} alt="The cultivation of the Sangha members 6">
      <p>
      Disciples of the two-fold assembly (monks and nuns) can no longer seek fame and social status.  Rather， they should possess the spirit of willingly suffer on behalf of all living beings， and the impartial mind to universally save all living beings.
      </p>
      <img src=${sangha07} alt="The cultivation of the Sangha members 7">
      <p>
      Sangha members should vigorously investigate Chan， practice meditation， uphold mantras， recite sutras， and strictly observe the precepts. If instead they simply rely on the Buddha for food and clothing， they will fall into the three evil paths.
      </p>
      <img src=${sangha08} alt="The cultivation of the Sangha members 8">
      <p>
      When filled with essence， cold does not exist；when filled with energy， hunger does not exist；when filled with spirit， exhaustion does not exist. Therefore， essence， energy， and spirit are three treasures cultivated by the Sangha members.
      </p>
      <img src=${sangha09} alt="The cultivation of the Sangha members 9">
      <p>
      Sangha members serve as models for laypeople. If you cannot be a good model， then you cannot evoke faith within the laypeople and you won't be able to influence them. Therefore， Sangha members should comport themselves in a way proper for Sangha members.
      </p>
      <img src=${sangha10} alt="The cultivation of the Sangha members 10">
      <p>
      Sangha members should have proper knowledge and proper views. Without them， one will surely fall into demonic ways and be caught by the fifty skandha-demons. Sangha members should do things together as a group.  One should not have a special style， or just do whatever one pleases.
      </p>
      <img src=${sangha11} alt="The cultivation of the Sangha members 11">
      <p>
      "Concentration yields efficacy. Distraction leads to hindrance.” What do we concentrate on? To attain concentration， one should get rid of desires and love. If desires and love are not cut off， then even if one leaves the home-life and cultivates for eighty thousand great eons， one will not achieve the Way. This point is of vital importance.
      </p>
      <img src=${sangha12} alt="The cultivation of the Sangha members 12">
      <p>
      We have to cultivate the Way and nurture our virtue at all the time. When our virtue and our cultivation in the Way are both perfected， then we will be worthy of being a Sangha member.
      </p>
      <img src=${sangha13} alt="The cultivation of the Sangha members 13">
      <p>
      Sangha members take propagating the Dharma as their main responsibility. Spreading the Buddhadharma is our basic duty and obligation. Therefore， our every thought should be devoted to propagating the Dharma. Our every deed and action should speak the Dharma for living beings.
      </p>
      <img src=${sangha14} alt="The cultivation of the Sangha members 14">
      <p>
      Sangha members should observe the Four Dignified Deportments when they walk， sit，stand， or recline. As it is said， ⁿWalk like the breeze， sit like a bell， stand like a pine tree， and when lying down， bend like a bow."
      </p>
     `,
    contentZh: `
      <img src=${sangha01} alt="出家行 1">
      <p>
      要四位出家人以上和合相處方稱為僧，是和諧共住，無爭無執。單獨一個出家人不能稱為僧。
      </p>
      <img src=${sangha02} alt="出家行 2">
      <p>
      不能又想出世法，又放不下世間法，腳踏兩條船，又要到江北，又要過江南，是辦不到的。
      </p>
      <img src=${sangha03} alt="出家行 3">
      <p>
      你們看古代高僧大德都是從苦行中得到悟境，沒有一位祖師從享受中得到開悟，把大藏經找遍了也找不到一位。
      </p>
      <img src=${sangha04} alt="出家行 4">
      <p>
      選擇住持以什麼爲標準？第一個條件要沒有脾氣。待人和氣，處處有人緣，不用權力壓迫任何人，以民主作風辦事，令人恭而敬之。
      </p>
      <img src=${sangha05} alt="出家行 5">
      <p>
      出家人可以受供養，但不可以貪供養，不貪圖供養，才是眞正佛的弟子。
      </p>
      <img src=${sangha06} alt="出家行 6">
      <p>
      現在已經出家的二眾弟子，不能再在名譽地位上來用功夫，要有代替眾生受苦的精神，要有普救一切眾生的平等心。
      </p>
      <img src=${sangha07} alt="出家行 7">
      <p>
      出家人若不精進修禪習定，持咒誦經，嚴守戒律，而倚佛吃飯，賴佛穿衣，必墮三途。
      </p>
      <img src=${sangha08} alt="出家行 8">
      <p>
      「精足不冷，氣足不餓，神足不睏」，精氣神是三寶，出家就是要修持精、氣、神。
      </p>
      <img src=${sangha09} alt="出家行 9">
      <p>
      出家人是在家人的榜樣。你這個榜樣，若做得不好，在家人就都不生信心了，影響力也沒有了，所以必須要出家人，有出家人的樣子。
      </p>
      <img src=${sangha10} alt="出家行 10">
      <p>
      出家人必須有正知正見，若沒有正知正見，一定會走火入魔，被五十陰魔抓走。出家人要共同一致行動，不可以自己別出心裁，要怎麼樣就怎麼樣。
      </p>
      <img src=${sangha11} alt="出家行 11">
      <p>
      「專一則靈，分馳則蔽。」專一什麼呢？專一必須斷欲去愛。若不斷欲去愛，就是出家修八萬大劫，也不會修成的，所以這是要緊的。
      </p>
      <img src=${sangha12} alt="出家行 12">
      <p>
      要時時刻刻都修道培德，把德性培圓滿了，道也修圓滿了，這才不愧爲一個出家人。
      </p>
      <img src=${sangha13} alt="出家行 13">
      <p>
      出家人以弘法爲家業，弘揚佛法就是我們的本份，我們的責任。所以我們要念茲在茲都在弘揚佛法，一舉一動都是給眾生說法。
      </p>
      <img src=${sangha14} alt="出家行 14">
      <p>
      出家人要嚴守行住坐臥四大威儀。所謂「行如風，坐如鐘，立如松，臥如弓」。
      </p>      
    `,
  },




  ];

export default blogsData;
