import React, { useEffect, useState } from "react";
import serviceData from "../data/Services";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const Service = ({ data }) => {
  const { t, i18n } = useTranslation();

  const [services, setServices] = useState([]);

  useEffect(() => {

    data === "*" ? setServices(serviceData) : setServices(serviceData.slice(0, data));

  }, [data]);

  return (
    <section id="services" className="services section" data-aos="fade-up">
      <div className="mycontainer">
        {/* SECTION INFO START */}
        <div className="infos mb-6">
          <h1 className="section-title has-text-centered">
            {t("services.name_section")}
          </h1>
          <p className="has-text-centered m-auto">{t("services.subtitle")}</p>
        </div>
        {/* SECTION INFO END */}
        {/* SERVICE CONTENT START */}
        <div className="service-content">
          {services.map((service, index) => (
            <div className="hm2-service-box" key={index}>
              <span className="icon-wrapper">
                <img
                  alt="icon"
                  loading="lazy"
                  width="70"
                  height="65"
                  decoding="async"
                  data-nimg="1"
                  className="img-fluid"
                  src={service.iconSrc}
                />
              </span>
              <h6 className="mt-32">
                {i18n.language === "en"
                  ? service.titleEn
                  : i18n.language === "zh"
                  ? service.titleZh
                  : service.titleEn}
              </h6>
              <p className="mb-32 mt-4 fw-medium">
                {i18n.language === "en"
                  ? service.descriptionEn
                  : i18n.language === "zh"
                  ? service.descriptionZh
                  : service.descriptionEn}
              </p>

              <Link className="explore-btn" to={`/services/id=${service.id}`}>
                {t("services.get_started")}
                <span className="ms-2">
                  <i className="fa-solid fa-arrow-right"></i>
                </span>
              </Link>
            </div>
          ))}
        </div>
        {/* SERVICE CONTENT END */}
      </div>
    </section>
  );
};

export default Service;
