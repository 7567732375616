import { useEffect, useState } from "react";
import scroll from "../assets/images/scroll-up/scroll-up.gif";

const Scroll = () => {
  const [scrollActive, setscrollActive] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      let scroll = window.scrollY;

      scroll > 200 ? setscrollActive(true) : setscrollActive(false);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleMove = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <div
      id="scrollUp"
      className={`${scrollActive ? "active" : ""}`}
      onClick={handleMove}
    >
      <figure className="image">
        <img src={scroll} alt="" />
      </figure>
    </div>
  );
};

export default Scroll;
