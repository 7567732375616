import React from "react";
import Banner from "../components/Banner";
import Question from "../components/Question";
import { useTranslation } from "react-i18next";
import Contact from "../components/Contact";

const ContactPage = () => {
  const { t } = useTranslation();

  return (
    <>
      <Banner path={t(`contact.name_section`)} />
      <Contact />
      <Question />
    </>
  );
};

export default ContactPage;
