import React, { useState } from "react";
import emailjs from "emailjs-com";
import { useTranslation } from "react-i18next";
import loading from "../assets/images/loading/loading.gif";

const Contact = () => {
  const { t } = useTranslation();

  // Define initial form data
  const initialFormData = {
    full_name: "",
    email: "",
    subject: "",
    message: "",
  };

  // State variables
  const [formData, setFormData] = useState(initialFormData);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSuccessAlertVisible, setIsSuccessAlertVisible] = useState(false);

  // Handle input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // Send email using emailjs
  const sendEmail = async () => {
    const emailService = {
      userId: "ioqnFEgkCcB0dvMPY",
      serviceId: "service_dma01tu",
      templateId: "template_7rr6glj",
    };

    try {
      await emailjs.send(
        emailService.serviceId,
        emailService.templateId,
        formData,
        emailService.userId
      );
      setIsSuccessAlertVisible(true);
      setFormData(initialFormData);
    } catch (error) {
      setIsSuccessAlertVisible(false);
    } finally {
      setIsSubmitting(false);
    }
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    setIsSubmitting(true);
    sendEmail();
  };

  return (
    <section className="contact section" data-aos="fade-up">
      <div className="mycontainer">
        <h2 className="title is-4">
          {t(`contact.say`)} <span>{t(`contact.hello`)}</span>
        </h2>
        <p className="mt-2 subtitle">{t(`contact.description`)}</p>

        <div className="columns mt-5">
          <div className="column">
            <form id="form" className="form" onSubmit={handleSubmit}>
              <label>
                <input
                  type="text"
                  id="full_name"
                  name="full_name"
                  value={formData.full_name}
                  onChange={handleInputChange}
                  placeholder={t(`contact.form.fullName`)}
                  required
                />
              </label>

              <div style={{ display: "flex", width: "100%", gap: "10px" }}>
                <label style={{ width: "50%" }}>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    value={formData.email}
                    onChange={handleInputChange}
                    placeholder={t(`contact.form.email`)}
                    required
                  />
                </label>

                <label style={{ width: "50%" }}>
                  <input
                    type="text"
                    id="subject"
                    name="subject"
                    value={formData.subject}
                    onChange={handleInputChange}
                    placeholder={t(`contact.form.subject`)}
                    required
                  />
                </label>
              </div>
              <label>
                <textarea
                  onChange={handleInputChange}
                  id="message"
                  cols="30"
                  rows="10"
                  name="message"
                  value={formData.message}
                  placeholder={t(`contact.form.message`)}
                  required
                ></textarea>
              </label>

              <div className="submit-area">
                <button type="submit" className="bx-btn custom-btn">
                  <span className="has-text-white">
                    {isSubmitting
                      ? t(`contact.form.submitting`)
                      : t(`contact.form.submit`)}
                  </span>
                </button>
              </div>
            </form>
          </div>
          <div className="column">
            <div className="content design_contact_info">
              <h3 className="title is-5 has-text-white">
                {t(`contact.additionalInfo.title`)}
              </h3>
              <p>{t(`contact.additionalInfo.description`)}</p>
              <ul>
                <li>{t(`contact.additionalInfo.item1`)}</li>
                <li>{t(`contact.additionalInfo.item2`)}</li>
                <li>{t(`contact.additionalInfo.item3`)}</li>
              </ul>
              {isSuccessAlertVisible && (
                <div className="notification">
                  {t(`contact.successMessage`)}
                </div>
              )}
            </div>
          </div>
        </div>
        {/* Start Iframe */}
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d155190.98213838894!2d-79.51872054326832!3d43.71762084911348!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89d4cb90d7c63ba5%3A0x323555502ab4c477!2sToronto%2C%20ON!5e0!3m2!1sen!2sca!4v1731018451148!5m2!1sen!2sca"
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
        ></iframe>
        {/* End Iframe */}
      </div>
    </section>
  );
};

export default Contact;
