import React, { useEffect, useRef, useState } from "react";
import mixitup from "mixitup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faImage, faPray, faLandmark } from "@fortawesome/free-solid-svg-icons";
import worksData from "../data/works";
import { useTranslation } from "react-i18next";
import "react-image-lightbox/style.css"; 
import Lightbox from "react-image-lightbox"; 

const Work = () => {
  const { t } = useTranslation();
  const containerRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false); 
  const [currentImageIndex, setCurrentImageIndex] = useState(0); 

  useEffect(() => {
    const mixer = mixitup(containerRef.current, {
      selectors: {
        target: ".tab-content",
      },
      animation: {
        duration: 300,
      },
    });

    return () => {
      mixer.destroy();
    };
  }, []);

  const openLightbox = (index) => {
    setCurrentImageIndex(index);
    setIsOpen(true);
  };

  const closeLightbox = () => {
    setIsOpen(false);
  };

  return (
    <section id="portfolio" className="portfolio section" data-aos="fade-up">
      <div className="mycontainer">
        <div className="infos mb-6">
          <h2 className="section-title has-text-centered">
            {t("portfolio.name_section")}
          </h2>
          <p className="has-text-centered m-auto">{t("portfolio.subtitle")}</p>
        </div>

        <div className="tabs is-centered">
          <ul>
            <li data-filter="*" className="filter-btn">
              <a>
                <span className="icon is-small">
                  <FontAwesomeIcon icon={faImage} aria-hidden="true" />
                </span>
                <span>{t("portfolio.tabs.all")}</span>
              </a>
            </li>
            <li data-filter=".monk" className="filter-btn">
              <a>
                <span className="icon is-small">
                  <FontAwesomeIcon icon={faPray} aria-hidden="true" />
                </span>
                <span>{t("portfolio.tabs.monk")}</span>
              </a>
            </li>
            <li data-filter=".temple" className="filter-btn">
              <a>
                <span className="icon is-small">
                  <FontAwesomeIcon icon={faLandmark} aria-hidden="true" />
                </span>
                <span>{t("portfolio.tabs.temple")}</span>
              </a>
            </li>
          </ul>
        </div>

        <div className="portfolio-content" ref={containerRef}>
          {worksData.map((item, index) => (
            <div key={index} className={`tab-content ${item.category}`}>
              <div className="card" onClick={() => openLightbox(index)}>
                <div className="card-image">
                  <figure className="image is-4by3">
                    <img src={item.imageUrl} alt="" />
                  </figure>
                </div>
              </div>
            </div>
          ))}
        </div>

      
      </div>
    </section>
  );
};

export default Work;
