import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const Question = () => {
  const { t } = useTranslation();

  const scrollToTop = () => {
    window.scrollTo({ top: 0 });
  };

  return (
    <section className="section" >
      <div className="mycontainer">
        <div className="main-content">
          <h3>
            {t(`contact.have`)}
            <span>{t(`contact.questions`)}</span>
          </h3>
          <p>{t(`contact.subtitle`)}</p>
          <Link to="/contact" className="custom-btn bx-btn" onClick={scrollToTop}>
            {t(`contact.name_section`)}
          </Link>
        </div>
      </div>
    </section>
  );
};

export default Question;
