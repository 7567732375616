import React, { useState, useEffect } from "react";
import Banner from "../components/Banner";
import Question from "../components/Question";
import serviceData from "../data/Services";
import { useParams, useNavigate, Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

// Import Images
import FirstImage from "../assets/images/services/img-1.jpg";
import SecImage from "../assets/images/services/img-2.jpg";
import avatarSrc from "../assets/images/services/author.png";

const ServiceDetails = () => {
  const { t, i18n } = useTranslation();
  const { id } = useParams();
  const [content, setContent] = useState("");
  const [relatedServices, setRelatedServices] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const service = serviceData.find((item) => item.id === Number(id));
    if (!service) {
      navigate(`/services`);
      return;
    }
    setContent(service);

    // Get related services excluding the current service
    const filteredServices = serviceData.filter(item => item.id !== Number(id));
    setRelatedServices(filteredServices);
  }, [id]);

  const categories = ["Buddhism", "Dharma Teaching", "佛教", "佛法"];

  return (
    <>
      <Banner path="Dharma Teaching" />
      <section className="blog-single-post section" data-aos="fade-up">
        <div className="container">
          <h2 className="post-title">
            {i18n.language === "en"
              ? content.titleEn
              : i18n.language === "zh"
              ? content.titleZh
              : content.titleEn}
          </h2>

          <div className="columns is-multiline">
            <div className="column is-8">
              <article>
                <div className="post-header">
                  <figure className="image post-thumbnail">
                    {content.srcImage && (
                      <img
                        width="885"
                        height="500"
                        src={content.srcImage}
                        alt="Buddhism"
                      />
                    )}
                  </figure>
                </div>
                <div className="post-categories tags">
                  {categories.map((category, index) => (
                    <Link
                      to=""
                      className="button bac-post-category-item tag"
                      key={index}
                    >
                      <span>{category}</span>
                    </Link>
                  ))}
                </div>

                <div className="post-metatags is-flex is-align-items-center">
                  <div className="author is-flex is-align-items-center">
                    <figure className="image author-avatar tutor-ratio">
                      {content.iconSrc && (
                        <img
                          className="is-rounded"
                          src={content.iconSrc}
                          alt="Buddhism"
                        />
                      )}
                    </figure>
                    <span className="author-name">Buddhism Nature</span>
                  </div>
                  <div className="post-publish-date is-flex is-align-items-center">
                    <span className="icon">
                      <i className="fa-solid fa-calendar-days"></i>
                    </span>
                    <h4 className="post-date">
                      {i18n.language === "en"
                        ? content.dateEn
                        : i18n.language === "zh"
                        ? content.dateZh
                        : content.dateEn}
                    </h4>
                  </div>
                </div>

                <div
                  className="post-content"
                  dangerouslySetInnerHTML={{
                    __html:
                      i18n.language === "en"
                        ? content.contentEn
                        : i18n.language === "zh"
                        ? content.contentZh
                        : content.contentEn,
                  }}
                ></div>
              </article>
            </div>
            <div className="column is-4">
              <aside className="sidebar blog-single-post-sidebar">

                {/* Other Services Blog */}
                <div className="post-widget default-widget related-posts mb-4">
                  <div className="widget-header">
                    <h2 className="widget-title">
                      {t(`services.related_articles`)}
                    </h2>
                  </div>
                  <div className="widget-body">
                    {relatedServices.map((service) => (
                      <div className="related-post-item" key={service.id}>
                        <div className="media">
                          <div className="media-left">
                            <figure className="image">
                              <img
                                width="256"
                                height="170"
                                src={service.srcImage || FirstImage} // Default image if not present
                                className="attachment-post-thumbnail size-post-thumbnail wp-post-image"
                                alt={service.titleEn || "Related Service"}
                              />
                            </figure>
                          </div>
                          <div className="media-content">
                            <h4>
                              <Link to={`/services/id=${service.id}`}>
                                {i18n.language === "en"
                                  ? service.titleEn
                                  : i18n.language === "zh"
                                  ? service.titleZh
                                  : service.titleEn}
                              </Link>
                            </h4>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>

              </aside>
            </div>
          </div>
        </div>
      </section>
      <Question />
    </>
  );
};

export default ServiceDetails;
