import About from "../components/About";
import Experience from "../components/Experience";
import Hero from "../components/Hero";
import Service from "../components/Service";
import Work from "../components/Work";
import News from "../components/News";

const Home = () => {
  return (
    <>
      <Hero />
      <About />
      <Service />
      <News />
      <Work />
    </>
  );
};

export default Home;
