import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import { useTranslation } from "react-i18next";

// Import Pages
import Home from "./pages/Home";
import About from "./pages/AboutPage";
import Portfolio from "./pages/PortfolioPage";
import Services from "./pages/ServicesPage";
import Experience from "./pages/ExperiencePage";
import News from "./pages/NewsPage";
import Contact from "./pages/ContactPage";

// Import Component
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import Scroll from "./components/Scroll";
import Loading from "./components/Loading";

// Import I18n
import "./i18n/i18n";

import { I18nextProvider } from "react-i18next";
import ServiceDetails from "./pages/serviceDetails";
import ServicesPage from "./pages/ServicesPage";
import SinglePost from "./pages/SinglePost";

const App = () => {
  const { i18n } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  }, []);

  document.dir = i18n.dir();

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <Router>
          <Navbar />
          <Scroll />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/portfolio" element={<Portfolio />} />
            <Route path="/services" element={<Services />} />
            <Route path="/services/id=:id" element={<ServiceDetails />} />
            <Route path="/experience" element={<Experience />} />
            <Route path="/news" element={<News />} />
            <Route path="/news/blog/:id" element={<SinglePost />} />
            <Route path="/contact" element={<Contact />} />
          </Routes>
          <Footer />
        </Router>
      )}
    </>
  );
};

export default App;
